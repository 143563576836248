import React, { useEffect, useState } from "react"
import { getUser } from "../services"
import { navigate } from "gatsby"

const RootContext = React.createContext({})

const RootContextProvider = props => {
  const [referrer, setReferrer] = useState(null)
  const [referrerUsername, setReferrerUsername] = useState(null)
  const [validating, setValidating] = useState(false)
  const [loadingReferrer, setLoadingReferrer] = useState(false)

  const [hasRan, setHasRan] = useState(false)
  const [screenSize, setScreenSize] = useState({
    height: 0,
    width: 0,
  })
  const updateScreenSize = () => {
    setScreenSize({ width: window.innerWidth, height: window.innerHeight })
  }

  const fetchUser = (username) => {
    setLoadingReferrer(true)
    setReferrerUsername(username)
  }

  useEffect(() => {
    if(!referrerUsername || referrer)
      return;

    setLoadingReferrer(true)
    getUser(referrerUsername, { referred: true }).then(res => {
      setReferrer(res.data.data)
    }).catch(error => {
      setReferrer(null)
      window.location.replace("/")
    }).finally(() => {
      setLoadingReferrer(false)
    })
  }, [referrerUsername])

  useEffect(() => {
    if (!hasRan) {
      setHasRan(true)
      updateScreenSize()
    }
    window.addEventListener("resize", updateScreenSize)
    return () => {
      window.removeEventListener("resize", updateScreenSize)
    }
  }, [screenSize, hasRan])

  return <RootContext.Provider value={{
    referrer,
    validating,
    fetchUser,
    setValidating,
    size: screenSize,
    loadingReferrer,
  }}>
    {props.children}
  </RootContext.Provider>
}

export const withRootContext = WrappedComponent => props => <RootContextProvider>
  <WrappedComponent {...props}/>
</RootContextProvider>

export default RootContext

export {
  RootContextProvider
}
