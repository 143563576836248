// Theme

const theme = {
  font: {
    primary: `'GT Haptik'`,
    secondary: `'HK Grotesk Medium'`,
    light: `'GT Haptik Light'`,
    normal: `'HK Grotesk Normal'`,
    medium: `'HK Grotesk Medium'`,
    semibold: `'HK Grotesk Semibold'`,
    bold: `'GT Haptik Bold'`,
    extrabold: `'HK Grotesk Extra Bold'`,
  },
  font_size: {
    xxxsmall: "font-size: 12px;",
    xxsmall: "font-size: 14px;",
    xsmall: "font-size: 16px;",
    small: "font-size: 17px;",
    regular: "font-size: 22px; line-height: 30px;",
    large: "font-size: 28px; line-height: 30px;",
    larger: "font-size: 40px; line-height: 50px;",
    xlarge: "font-size: 48px; line-height: 48px;",
  },

  color: {
    primary: "#16445E",
    warning: "#E61E27",
    secondary: "#098c8c",
    accent: "#cca86e",
    background: {
      white: "#ffffff",
      light: "#f8f8f8",
    },
    white: {
      regular: "#ffffff",
      lessdark: "#faf9f8",
      dark: "#f6f6f6",
      darker: "#eeeeee",
    },
    grey: {
      light: '#D2EAEF'
    },
    black: {
      lightest: "#ABA8AF",
      light: "#564F62",
      regular: "#071435",
    },
  },

  input: {
    background: `linear-gradient(180deg, #FFFFFF 0%, #E5E5E5 100%)`,
    hint: `#16445E`,
    boxShadow: `0px 2px 8px rgba(22, 68, 94, 0.12)`,
    border: `1px solid rgba(22, 68, 94, 0.1)`,
    borderRadius: `20px`,
    error: {
      background: 'linear-gradient(180deg, #FFF4F5 0%, #FFDDDF 100%)',
      border: '1px solid rgba(230, 30, 39, 0.5)',
    }
  },

  button: {
    background: `linear-gradient(180deg, #FF545C 0%, #E61E27 50%, #CE1921 100%);`,
    boxShadow: `0px 2px 8px rgba(0, 0, 0, 0.16)`,
    borderRadius: `20px`,
    textShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
    fontWeight: 'bold',
    color: 'white',
    secondary: {
      color: '#16445E',
      background: `linear-gradient(180deg, #FFFFFF 0%, #E5E5E5 100%);`,
      textShadow: 'none',
      boxShadow: '0px 2px 8px rgba(22, 68, 94, 0.12)'
    }
  },

  choice: {
    background: `linear-gradient(180deg, #5992FF 0%, #2262DD 100%);`,
    boxShadow: `0px 2px 8px rgba(22, 68, 94, 0.12)`
  },

  snackbar: {
    background: `linear-gradient(180deg, #5992FF 0%, #2262DD 100%)`,
  },

  screen: {
    xs: "575px",
    sm: "767px",
    md: "991px",
    lg: "1199px",
  },
}

export default theme
