import React, { useContext } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import RootContext from "../../../contexts/RootContext"

const SEO = (props) => {
  const { lang, meta, title } = props
  const { referrer } = useContext(RootContext)

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = site.siteMetadata.description
  const OG_IMAGE = `${process.env.GATSBY_OG_IMAGE_BASE_URL}${referrer?.username ?? 'defaults/og-image'}.png`
  const OG_URL = `https://felix.fm/${referrer?.username ?? ""}`
  const mainTitle = (title ? `${title} | ` : "") + site.siteMetadata.title

  return (
      <Helmet
        defer={false}
        htmlAttributes={{
          lang,
        }}
        title={mainTitle + site.siteMetadata.title}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          { name: 'apple-mobile-web-app-capable', content: 'yes' },
        ].concat(meta)}
      >
        {/* OpenGraph tags */}
        <meta property="og:title" content={referrer?.username ? `Turn up with ${referrer?.nickname} (@${referrer?.username})`: mainTitle} />
        <meta property="og:type" content={`website`} />
        <meta property="og:site_name" content={`felix.fm`} />
        <meta property="og:url" content={OG_URL} />
        <meta property="og:image" content={OG_IMAGE} />
        <meta property="image" content={OG_IMAGE} />
        <meta property="og:description" content='Felix is for those that want to bop and feel good.' />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={site.siteMetadata.author} />
        <meta name="twitter:title" content={referrer?.username ? `Turn up with ${referrer?.nickname} (@${referrer?.username})` : mainTitle} />
        <meta name="twitter:image" content={OG_IMAGE} />
        <meta name="twitter:description" content='Felix is for those that want to bop and feel good.' />
        <meta name="twitter:url" content={OG_URL} />

      </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
