import axios from 'axios';

const Api = axios.create({
  baseURL: `${process.env.GATSBY_API_URL}`,
});

export const join = input => {
  const data = {...input, phoneNumber: `${input["phoneNumber-code"]}${input["phoneNumber"]}`}
  return Api.post(`/join`, {
    ...data,
    airpods: data['airpods'].map(item => item.name).join(','),
    phone: data['phone'].map(item => item.name).join(','),
    spotify: data['spotify'].map(item => item.name).join(','),
    survey: data['survey'].map(item => item.name).join(','),
  })
};

export const validate = input => {
  return Api.get(`/validate?${Object.keys(input).map(key => `${key}=${input[key]}`).join('&')}`)
};

export const getUser = (username, {referred = null}) => {
  return Api.get(`/user/${username}${referred ? '?referred=true' : ''}`)
};

export default Api;
