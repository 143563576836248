import React, { useContext } from "react"
import PropTypes from "prop-types"
import styled, { ThemeProvider } from "styled-components"
import Popover from 'react-tiny-popover'

import theme from "../../../styles/theme"
import GlobalStyles from "../../../styles/GlobalStyles"
import "../../../static/fonts/fonts.css"
import backgroundBottom from '../../../images/svgs/landscape.svg'
import backgroundBottomSM from '../../../images/svgs/landscape-sm.svg'
import { graphql, useStaticQuery, navigate } from "gatsby"
import Img from "gatsby-image"
import { useSpring, animated } from 'react-spring'
import cloud from "../../../images/svgs/cloud.svg"
import RootContext from "../../../contexts/RootContext"
import SoundToggle from "../sound-toggle"

const ROUTES = {
  "/details": "Sup, new homie!",
  "/details/audio": "Audio wizardry goes down here.",
  "/details/habits": "Wassup with your AM flow?",
  "/details/summary": "Yo!"
}

const Layout = props => {
  const { children, openSideNav } = props
  const context = useContext(RootContext)

  const data = useStaticQuery(graphql`
    query {
      sun: file(sourceInstanceName: {eq: "common"}, name: {eq: "sun"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      vfLogo: file(sourceInstanceName: {eq: "common"}, name: {eq: "vf-logo"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      felixLogo: file(sourceInstanceName: {eq: "common"}, name: {eq: "felix-logo"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      captain: file(sourceInstanceName: {eq: "common"}, name: {eq: "felix"}) {
        childImageSharp {
          fluid(maxWidth: 2200, quality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      close: file(sourceInstanceName: {eq: "common"}, name: {eq: "close"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  const opacityProps = useSpring({ opacity: openSideNav ? 0 : 1 })
  const mascotLocationProps = useSpring({ right: openSideNav ? '-3vw' : '0vw' })

  const AnimatedVFLogo = animated(VFLogo)
  const AnimatedImageContainer = animated(ImageContainer)

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles/>
        <Container size={context.size}>
          <HeaderContainer>
            <FelixLogo openSideNav={openSideNav} fluid={data.felixLogo.childImageSharp.fluid}/>
            <SoundIcon color='secondary'>
              <SoundToggle onChange={console.log}/>
            </SoundIcon>
          </HeaderContainer>
          <SideBar size={context.size} opened={props?.location?.pathname?.startsWith("/details")}>
            <SideBarContent openSideNav={openSideNav}>
              <SideBarChildren openSideNav={openSideNav}>
                {children}
                <CloseIcon onClick={() => {
                  navigate(`/${context?.referrer?.username ?? ""}`)
                }}>
                  <Img style={{ width: '100%' }} fluid={data.close.childImageSharp.fluid} />
                </CloseIcon>
              </SideBarChildren>
            </SideBarContent>
          </SideBar>
          <ContentItem openSideNav={openSideNav}>
            <FelixLogoSM openSideNav={openSideNav} fluid={data.felixLogo.childImageSharp.fluid}/>
            {!openSideNav && children}
            <a href='http://viralfoundry.com' rel="noreferrer" target='_blank'>
              <VFLogoSM fluid={data.vfLogo.childImageSharp.fluid}/>
            </a>
          </ContentItem>
          <AnimatedImageContainer style={mascotLocationProps}>
            <Popover
              isOpen={openSideNav}
              position={'top'}
              contentLocation={
                ({ targetRect, popoverRect, position, align, nudgedLeft, nudgedTop }) =>
                {
                  return ({ top: targetRect.top - 100, left: targetRect.left + 50 })
                }}
              content={
                <CloudContainer>
                  <CloudImage src={cloud} alt='Cloud'/>
                  <CloudText>
                    {ROUTES[props?.location?.pathname]}
                  </CloudText>
                </CloudContainer>
              }
            >
              <div>
                <StyledImage loading="eager" imgStyle={{
                  objectFit: "contain"
                }} fluid={data.captain.childImageSharp.fluid} />
              </div>
            </Popover>
          </AnimatedImageContainer>
          <BackgroundItem>
            <BottomImageMain src={backgroundBottom} alt='bottom-landscape'/>
            <BottomImageSM src={backgroundBottomSM} alt='bottom-landscape'/>
          </BackgroundItem>
          <TopCorner>
            <SunImage imgStyle={{ objectFit: 'contain' }} fluid={data.sun.childImageSharp.fluid}/>
          </TopCorner>
          <LogoContainer openSideNav={openSideNav}>
            <a href='http://viralfoundry.com' rel="noreferrer" target='_blank'>
              <AnimatedVFLogo style={opacityProps} fluid={data.vfLogo.childImageSharp.fluid}/>
            </a>
          </LogoContainer>
        </Container>
      </>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  openSideNav: PropTypes.bool
}

Layout.defaultProps = {
  openSideNav: false
}

const CloudText = styled.div`
  position: absolute;
  height: 65%;
  display: flex;
  align-items: center;
  top: 2vh;
  font-size: 1.3vw;
  font-family: "GT Haptik Bold";
  font-weight: 900;
  text-align: center;
  padding: 0 2.1vw 0 1.2vw;
`

const CloudImage = styled.img`
  position: absolute; 
  top: 0;
  width: 15vw;
  height: 15vw; 
  objectFit: contain;
`

const CloudContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15vw;
  height: 15vw;
  object-fit: contain;
  @media 
  (max-width: ${props => props.theme.screen.sm}),
  only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    display: none;
  }
`

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 10vh repeat(3, 1fr) 10vh;
  // height: ${props => props.size?.height}px;
  overflow: hidden;
  > * {
    overflow: hidden;
  }
  @media (max-width: ${props => props.theme.screen.sm}),
  only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait){
    grid-template-rows: 10vh repeat(3, 1fr) 10vh;
  }
`

const StyledImage = styled(Img)`
  width: 50vw;
  height: 75vh;
  object-fit: contain;
`

const BackgroundItem = styled.div`
  grid-area: 1 / 1 / 6 / 6;
  display: flex;
  align-items: flex-end;
`

const BottomImage = styled.img`
  width: 100%;
  height: 50vh;
  object-fit: cover;
`

const BottomImageMain = styled(BottomImage)`
  display: block;
  @media (max-width: ${props => props.theme.screen.sm}),
  only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    display: none;
  }
`

const BottomImageSM = styled(BottomImage)`
  display: none;
  @media (max-width: ${props => props.theme.screen.sm}),
  only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    display: block;
  }
`

const ContentItem = styled.div`
  grid-area: 2 / 1 / 5 / 6;
  z-index: ${props => props.openSideNav ? '3' : '4'};
  display: flex;
  width: 50%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  opacity: ${props => props.openSideNav ? 0 : 1};
  transition: ${props => props.openSideNav ? 'opacity 0.8s ease-in-out 0s' : 'opacity 0.8s ease-in-out 0.8s'};
  @media (max-width: ${props => props.theme.screen.sm}),
  only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    grid-area: 1 / 1 / 6 / 6;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 0;
    width: 100%;
    transition: unset;
  }
`

const ImageContainer = styled.div`
  grid-area: 1 / 1 / 6 / 6;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  @media (max-width: ${props => props.theme.screen.sm}),
  only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    display: none;
  }
`

const SideBar = styled.div`
  grid-area: 1 / 1 / 6 / 6;
  overflow: hidden;
  height: ${props => props.size?.height}px;
  display: flex;
  flex-direction: row;
  z-index: ${props => props.opened ? '6' : '3'};
`

const SideBarChildren = styled.div`
  opacity: ${props => props.openSideNav ? 1 : 0};
  transition: ${props => props.openSideNav ? 'opacity 0.8s ease-in-out 0.8s' : 'initial'};
  @media (max-width: ${props => props.theme.screen.sm}),
  only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    transition: unset;
  }
`

const SideBarContent = styled.div`
  height: 100%;
  flex-direction: column;
  display: block;
  width: ${props => props.openSideNav ? '50%' : 0};
  align-items: center;
  justify-content: center;
  background: linear-gradient(189.21deg, #C4F4FF 1.67%, #E7FBFF 68.05%);
  backdrop-filter: blur(24px);
  font-size: 15px;
  transition: width 0.8s ease-in-out 0s;
  @media (max-width: ${props => props.theme.screen.sm}),
  only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    width: ${props => props.openSideNav ? '100%' : 0};
    transition: unset;
  }
`

const CloseIcon = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 30px; 
  right: 30px; 
  width: 50px; 
  height: 50px;
  font-size: 2rem;
  font-weight: 500;
  color: black;
  background: white;
  border-radius: 50%;
  cursor: pointer;
  @media (max-width: ${props => props.theme.screen.sm}),
  only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    display: none;
  }
`

const TopCorner = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  align-items: start;
  justify-content: start;
  overflow: visible;
`

const SunImage = styled(Img)`
  width: 20vh;
  @media (max-width: ${props => props.theme.screen.sm}),
  only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    width: 10vh;
  }
`

const LogoContainer = styled.div`
  grid-area: 5 / 1 / 6 / 6;
  display: flex;
  align-items: center;
  z-index: 5;
  justify-content: center;
  @media (max-width: ${props => props.theme.screen.sm}),
  only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    display: none;
  }
`

const FelixLogo = styled(Img)`
  width: 4vw; 
  min-width: 80px;
  opacity: ${props => props.openSideNav ? 0 : 1};
  transition: opacity 0.5s ease-in-out 0s;
  @media (max-width: ${props => props.theme.screen.sm}),
  only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    display: none;
    opacity: 1;
    min-width: 60px;
    z-index: 5;
  }
`

const FelixLogoSM = styled(FelixLogo)`
  display: none;
  height: auto;
  width: 40px;
  margin: 8px 0 24px 0;
  @media (max-width: ${props => props.theme.screen.sm}),
  only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    display: flex;
    min-width: unset;
  }
  @media only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    display: flex;
    min-width: 60px;
  }
`

const SoundIcon = styled.div`
  position: absolute;
  margin: unset;
  right: 1.2vh;
  z-index: 10;
  @media (max-width: ${props => props.theme.screen.sm}),
  only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    display: none;
  }
`

const VFLogo = styled(Img)`
  width: 8vw; 
  min-width: 120px;
  @media (max-width: ${props => props.theme.screen.sm}),
  only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    display: none;
  }
`

const VFLogoSM = styled(Img)`
  display: none;
  height: auto;
  width: 100px;
  margin: 8px 0;
  @media (max-width: ${props => props.theme.screen.sm}), 
  only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
    display: flex;
  }
`

const HeaderContainer = styled.div`
  grid-area: 1 / 1 / 2 / 6;
  display: flex;
  align-items: center;
  padding-top: 2vh;
  justify-content: center;
`

export default Layout
