import styled from "styled-components"
import Button from "./forms/button"
import soundOFF from "../../images/svgs/volume-off.svg"
import soundOn from "../../images/svgs/volume-on.svg"
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

const Container = styled(Button)`
  margin: unset;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(22, 68, 94, 0.1);
  box-shadow: 0px 2px 8px rgba(22, 68, 94, 0.12);
  background-clip: padding-box;
  cursor: pointer;
`

const SoundToggle = props => {
  const [on, setOn] = useState(false);

  useEffect(() => {
    props.onChange(on)
  }, [on, props]);

  return <Container color='secondary' onClick={(e) => {
    e.stopPropagation();
    setOn(!on);
  }}>
    <img src={on ? soundOn : soundOFF} alt='sound'/>
  </Container>
}

SoundToggle.propTypes = {
  onChange: PropTypes.func
}

export default SoundToggle
