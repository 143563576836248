import styled from "styled-components"

const Button = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  display: block;
  margin: 16px 0px 16px 0px;
  cursor: pointer;
  white-space: nowrap;
  font-weight: ${props => props.theme.button.fontWeight};
  text-shadow: ${props => props.theme.button.textShadow};
  background: ${props => props.color === 'secondary' ? props.theme.button.secondary.background : props.theme.button.background};
  border-radius: ${props => props.theme.button.borderRadius};
  box-shadow: ${props => props.theme.button.boxShadow};
  padding: 20px 40px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

export default Button;
